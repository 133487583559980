<template>
  <div class="sell-investment">
    <div class="container-xl">
      <div class="card">
        <div class="card-header">
          <div class="card-title">Настройки оферты вторичного рынка</div>
        </div>
        <div v-if="!investment.uuid" class="text-center p-5"><i>Загрузка...</i></div>
        <div v-if="investment.uuid" class="card-body">
          <fieldset>
            <legend>Информация о инвестиции</legend>
            <div class="row">
              <div class="col-12 col-lg-4">
                <div class="h6">{{ investment.project.name }}</div>
                <div class="text-muted">ID {{ investment.project.paymentCode }}</div>
              </div>
              <div class="col-6 col-sm-3 col-lg-2 mt-2 mt-lg-0">
                <div class="small text-muted">Сумма инвестиции</div>
                <money :money="investment.money"/>
              </div>
              <div class="col-6 col-sm-3 col-lg-2 mt-2 mt-lg-0 text-right text-sm-center">
                <div class="small text-muted">Процентная ставка</div>
                {{ investment.project.interestRate }}%
              </div>
              <div class="col-6 col-sm-3 col-lg-2 mt-2 mt-lg-0 text-sm-center">
                <div class="small text-muted">Месяц</div>
                {{ investment.nextRepayment ? investment.nextRepayment.month : investment.project.initialTerm }}
                / {{ investment.project.initialTerm }}
              </div>
              <div class="col-6 col-sm-3 col-lg-2 mt-2 mt-lg-0 text-right">
                <div class="small text-muted">Дата сл. платежа</div>
                <date-format v-if="investment.nextRepayment" :date="investment.nextRepayment.date"/>
                <span v-if="!investment.nextRepayment">-</span>
              </div>
            </div>
          </fieldset>
          <form @input="validateForm" @change="validateForm" @submit="sell">
            <div class="row">
              <div class="col-12 col-md-6 col-lg-5">
                <div class="card-subtitle mb-3">
                  Оферта продажи инвестиции
                  <img v-if="offer.guaranteed" src="@/assets/img/bay_back.png" alt="Buy Back" class="buy-back">
                </div>
                <div class="row">
                  <div class="col-6 col-md-7 col-lg-6 pr-md-1 pr-lg-4">
                    <div class="form-group">
                      <label for="offer-amount" class="text-muted">Размер продаваемой инвестиции</label>
                      <input-money v-model="offer.amount"
                                   :max="Math.floor(investment.sumForSale.amount / 100)"
                                   :min="0"
                                   :currency="true"
                                   @change="calculatePrice"
                                   id="offer-amount"
                                   class="form-control"
                                   placeholder="10 000"
                                   required/>
                      <input-errors :errors="errors.amount"/>
                    </div>
                  </div>
                  <div class="col-6 col-md-5 col-xl-4 px-md-1">
                    <div class="form-group">
                      <label for="offer-discount" class="text-muted">
                        <span class="d-sm-none d-md-inline"><br></span>
                        % наценки / дисконта
                      </label>
                      <div class="input-group">
                        <input v-model="offer.discount"
                               min="-99"
                               max="99"
                               step="0.1"
                               @input="calculatePrice"
                               :readonly="offer.guaranteed"
                               type="number"
                               id="offer-discount"
                               class="form-control"
                               placeholder="12"
                               required>
                        <div class="input-group-append">
                          <div class="input-group-text">%</div>
                        </div>
                      </div>
                      <input-errors :errors="errors.discount"/>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6 col-md-7 col-lg-6 pr-md-1 pr-lg-4">
                    <div class="form-group">
                      <label for="offer-price" class="text-muted">Цена продаваемой инвестиции</label>
                      <input-money v-model="offer.price"
                                   :min="0"
                                   :currency="true"
                                   @change="calculateDiscount"
                                   :readonly="offer.guaranteed"
                                   id="offer-price"
                                   class="form-control"
                                   placeholder="10 120"
                                   required/>
                      <input-errors :errors="errors.price"/>
                    </div>
                  </div>
                  <div class="col-6 col-md-5 col-xl-4 px-md-1">
                    <label for="offer-expired-at" class="text-muted">Срок действия оферты до</label>
                    <input v-model="offer.expiredAt"
                           type="date"
                           id="offer-expired-at"
                           class="form-control"
                           autocomplete="off"
                           required>
                    <input-errors :errors="errors.expiredAt"/>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-5 offset-lg-2">
                <div v-if="guarantorFeature">
                <div class="form-group block-guarantee">
                  <div class="custom-control custom-switch">
                    <input v-model="offer.guaranteed" class="custom-control-input" type="checkbox" id="guaranteed">
                    <label class="custom-control-label" for="guaranteed">Продать с гарантией откупа</label>
                  </div>
                  <div v-if="!offer.guaranteed" class="mt-3">
                    Право выставить на продажу инвестицию с гарантией откупа имеют только инвесторы-юридические лица, получившие специальную
                    аккредитацию на Платформе.
                  </div>
                </div>
                <div class="row">
                  <div class="col-6 col-md-5 col-xl-4 px-md-1">
                    <div v-if="offer.guaranteed" class="form-group">
                      <label for="guarantor-percent" class="text-muted">Размер продаваемой ставки</label>
                      <div class="input-group">
                        <input v-model="offer.salePercent"
                               :min="0"
                               :max="investment.project.interestRate"
                               step="0.1"
                               @change="calculateProfit"
                               type="number"
                               class="form-control"
                               id="guarantor-percent"
                               placeholder="6"
                               required>
                        <div class="input-group-append">
                          <div class="input-group-text">%</div>
                        </div>
                      </div>
                      <input-errors :errors="errors.salePercent"/>
                    </div>
                  </div>
                </div>
                </div>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-12 text-right">
                <div class="form-group">
                  <button type="submit" class="btn btn-success">
                    Продать
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DateFormat, InputErrors, InputMoney, Money } from "@/components/common";
import { appConfig } from "@/configs";
import { Money as ApiMoney } from "@/utils/api-client";
import { createInvestmentManager, createOfferSaleManager } from "@/utils/di";
import moment from "moment";

export default {
  name: "SaleInvestment",
  components: {
    DateFormat,
    InputErrors,
    InputMoney,
    Money
  },
  inject: ["profile"],
  data() {
    return {
      investment: {},
      offer: {
        amount: null,
        price: null,
        discount: null,
        profit: null,
        expiredAt: null,
        guaranteed: false,
        salePercent: null
      },
      errors: {},
      guarantorFeature: appConfig.guarantorFeature && this.profile.secondaryMarketV2Allow
    };
  },
  async mounted() {
    const manager = await createInvestmentManager();
    const investment = await manager.getOneLoanPayment(this.$route.params.uuid);
    if (investment.guarantorPercent) {
      return this.$router.replace({ name: "myInvestments" })
    }
    this.investment = investment;
    this.offer.amount = Math.floor(this.investment.sumForSale.amount / 100);
    this.offer.price = this.offer.amount;
    this.offer.discount = 0;
    this.calculateProfit();
  },
  methods: {
    calculatePrice() {
      const amount = parseInt(this.offer.amount);
      const discount = parseFloat(this.offer.discount);
      if (!isNaN(amount) && !isNaN(discount)) {
        this.offer.price = Math.round(amount + amount * discount / 100);
        this.calculateProfit();
      }
    },
    calculateDiscount() {
      const amount = parseInt(this.offer.amount);
      const price = parseInt(this.offer.price);
      if (!isNaN(amount) && !isNaN(price)) {
        this.offer.discount = Math.round((price - amount) / amount * 100);
        this.calculateProfit();
      }
    },
    calculateProfit() {
      const amount = parseInt(this.offer.amount);
      const price = parseInt(this.offer.price);
      const salePercent = this.offer.guaranteed ? parseFloat(this.offer.salePercent) : 0;

      if (!isNaN(amount) && !isNaN(price) && !isNaN(salePercent) && this.investment.nextRepayment) {
        const monthlyInterestRate = salePercent / 12;
        const monthlyRepaymentAmount = amount * monthlyInterestRate / 100;
        const monthsLeft = this.investment.project.initialTerm - this.investment.nextRepayment.month;
        const repaymentAmount = amount + monthlyRepaymentAmount * monthsLeft;
        this.offer.profit = (((repaymentAmount - price) / price * 100) / monthsLeft * 12).toFixed(2);
      }
    },
    validateForm() {
      const errors = {};
      if (this.offer.amount <= 0) {
        errors.amount = ['Размер продаваемой инвестиции должен быть болше ноля'];
      } else if (this.offer.amount <= 0) {
        errors.amount = ['Размер продаваемой инвестиции не может быть больше размера инвестиции'];
      }
      if (this.offer.price <= 0) {
        errors.price = ['Цена должна быть болше ноля'];
      }
      if (this.offer.discount <= -100) {
        errors.discount = ['% дисконта должен быть меньше 100'];
      } else if (this.offer.discount >= 100) {
        errors.discount = ['% наценки должен быть меньше 100'];
      }
      if (!this.offer.expiredAt) {
        errors.expiredAt = ['Не указан срок действия оферты'];
      } else if (!moment(this.offer.expiredAt).isValid()) {
        errors.expiredAt = ['Не верно указан формат даты'];
      } else if (moment(this.offer.expiredAt).diff(Date(), "days", true) <= 0) {
        errors.expiredAt = ['Дата истечения срока действия оферты должна быть больше текущей даты'];
      }
      if (this.guarantorFeature && this.offer.guaranteed) {
        if (this.offer.salePercent <= 0) {
          errors.salePercent = ['Размер продаваемой ставки должен быть больше ноля'];
        } else if (this.offer.salePercent > this.investment.project.interestRate) {
          errors.salePercent = ['Размер продаваемой ставки не должен быть больше процентной ставки'];
        }
      }
      this.errors = errors;
      return Object.keys(errors).length === 0;
    },
    async sell(e) {
      e.preventDefault();
      if (this.validateForm()) {
        this.processing = true;
        try {
          const manager = await createOfferSaleManager();
          const price = this.offer.guaranteed ? this.offer.amount : this.offer.price;
          const result = await manager.createConfirmation(
              this.investment.uuid,
              new ApiMoney(this.offer.amount * 100, this.investment.money.currencyCode),
              new ApiMoney(price * 100, this.investment.money.currencyCode),
              new Date(this.offer.expiredAt),
              true, // we decided it is always splittable
              this.offer.guaranteed ? this.investment.project.interestRate - this.offer.salePercent : undefined
          );
          await this.$router.push({ name: "saleConfirmation", params: { uuid: this.investment.uuid, confirmationUuid: result.uuid } });
        } finally {
          this.processing = false;
        }
      }
    }
  },
  watch: {
    "offer.guaranteed"(value) {
      if (value) {
        this.offer.discount = 0;
        this.offer.price = this.offer.amount;
        this.calculateProfit();
      }
    }
  }
}
</script>

<style lang="scss">
.sell-investment {
  fieldset {
    border-style: solid;
    border-color: #ecedef;
    border-width: 0.125rem 0;
    padding: 1rem 0;
    margin-bottom: 1rem;

    legend {
      width: auto;
      font-size: 1rem;
      margin: 0;
      padding: 0 0.5rem 0 0;
    }
  }

  dl {
    margin-top: 1rem;

    dd {
      text-align: right;
    }
  }

  .card-subtitle {
    height: 2.5rem;
    line-height: 2.5rem;
  }

  .block-guarantee {
    margin: 0.55rem 0 1.5rem;
  }

  .buy-back {
    height: 2.5rem;
  }

  .col-guarantor-percent {
    flex: 0 0 41%;
    max-width: 41%;
  }

  @media (min-width: 576px) { // Bootstrap SM
    .col-guarantor-percent {
      flex: 0 0 41.66667%;
      max-width: 41.66667%;
    }
  }

  @media (min-width: 768px) { // Bootstrap MD
    .col-guarantor-percent {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
  }
}
</style>
